var socialAPIs = {};

socialAPIs.Facebook = {
    name: "Facebook",
    farm: "FACEBOOK",
    order: 1,
    accessButtonDefaultImageUrl: 'www.altalex.it/Content/Altalex/images/social/fblogin.png',
    deferredInit: $.Deferred(),
    init: function () {
        var def = socialAPIs.Facebook.deferredInit;
        socialAPIs.Facebook.inited = false;
        socialAPIs.Facebook.logged = false;

        window.fbAsyncInit = function () {
            try {
                // call facebook init
                FB.init({
                    appId: fbAppId,
                    cookie: true,  // enable cookies to allow the server to access the session
                    xfbml: true,  // parse social plugins on this page
                    version: 'v2.1' // use version 2.1
                });

                FB.getLoginStatus(function (response) {
                    socialAPIs.Facebook.inited = true;
                    console.log("FACEBOOK API INITED!"); //Facebook � inizializzato
                    socialAPIs.Facebook.logged = response.status === 'connected';
                    def.resolve(socialAPIs.Facebook.logged);
                });
            }
            catch (error) {
                socialAPIs.Facebook.inited = false;
                def.resolve(false); //In caso di errore, comunque risolvo
                console.error(error);
            }
        };

        try {
            (function (d, s, id) {
                var js, fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = "//connect.facebook.net/it_IT/all.js";
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        }
        catch (error) {
            socialAPIs.Facebook.inited = false;
            def.resolve(false); //In caso di errore, comunque risolvo
            console.error(error);
        }

        return def.promise();
    },
    login: function () {
        var def = $.Deferred();
        try {
            FB.getLoginStatus(function (response) {
                socialAPIs.Facebook.logged = response.status === 'connected';
                
                if (socialAPIs.Facebook.logged) {
                    window.dataLayer.push({ 'event': 'login-facebook-ok' });
                    def.resolve();         
                }
                else
                    FB.login(function (response) {
                        if (response.status === 'connected') {
                            def.resolve(response);
                        } else {
                            def.reject(response);
                        }
                    }, { scope: 'public_profile,email' });
            });
        }
        catch (error) {
            def.reject(error);
            window.dataLayer.push({ 'event': 'login-facebook-error' });
            console.error(error);
        }
        return def.promise();
    },
    getSocialData: function () {
        var def = $.Deferred();
        try {
            FB.api('/me', function (response) {
                def.resolve(new SocialData(response.id, response.email, response.first_name, response.last_name, socialAPIs.Facebook.farm, socialAPIs.Facebook.name));
            });
        }
        catch (error) {
            def.reject(error);
            console.error(error);
        }
        return def.promise();
    }
};

socialAPIs.LinkedIn = {
    name: "LinkedIn",
    farm: "LINKEDIN",
    order: 2,
    accessButtonDefaultImageUrl: 'www.altalex.it/Content/Altalex/images/social/lilogin.png',
    deferredInit: $.Deferred(),
    init: function () {
        var def = socialAPIs.LinkedIn.deferredInit;
        socialAPIs.LinkedIn.inited = false;
        socialAPIs.LinkedIn.logged = false;

        $.getScript("//platform.LinkedIn.com/in.js?async=true", function success() {
            try {
                IN.Event.on(IN, 'systemReady', function () {
                    socialAPIs.LinkedIn.inited = true;
                    console.log("LINKEDIN API INITED!");
                    socialAPIs.LinkedIn.logged = IN.User.isAuthorized();
                    def.resolve(socialAPIs.LinkedIn.logged);
                });

                IN.init({
                    api_key: li_api_key,
                    //onLoad: "lnAPILoaded",
                    authorize: true,
                    credentials_cookie: true,
                    lang: 'it_IT'
                });
            }
            catch (error) {
                socialAPIs.LinkedIn.inited = false;
                def.resolve(false); //In caso di errore, comunque risolvo
                console.error(error);
            }
        }).fail(function (error) {
            socialAPIs.LinkedIn.inited = false;
            def.resolve(false); //In caso di errore, comunque risolvo
            console.error(error);
        });
        return def.promise();
    },
    login: function () {
        var def = $.Deferred();
        try {
            if (IN.User.isAuthorized()) {
                window.dataLayer.push({ 'event': 'login-linkedin-ok' });
                def.resolve();
            }
            else {
                //var x = IN.UI.Authorize().place();
                //debugger;
                IN.User.authorize(function (response) {
                    def.resolve();
                });
            }
        }
        catch (error) {
            def.reject(error);
            window.dataLayer.push({ 'event': 'login-linkedin-error' });
            console.error(error);
        }
        return def.promise();
    },
    getSocialData: function () {
        var def = $.Deferred();
        IN.API.Profile("me")
            .fields("id,emailAddress,first-name,last-name,siteStandardProfileRequest")
            .result(function (me) {
                var userData = me.values[0];
                var id = userData.id;
                var email = userData.emailAddress;
                var nome = userData.firstName;
                var cognome = userData.lastName;
                def.resolve(new SocialData(id, email, nome, cognome, socialAPIs.LinkedIn.farm, socialAPIs.LinkedIn.name));
            })
            .error(function (error) {
                def.reject(error);
                console.error(error);
            });
        return def.promise();
    }
};

var SocialData = function (id, email, nome, cognome, farm, networkname) {
    this.Id = id;
    this.Email = email;
    this.Nome = nome;
    this.Cognome = cognome;
    this.Farm = farm;
    this.NetworkName = networkname;
};

//ALL SOCIALS INITED

/*
configuration deve contenere:
- le url dele immagini dei pulsanti di accesso social, per ciascun social es. { Facebook: 'url1.jpg', Linkedin: 'url2.jpg' }
- la url della pagina di registrazione (Command = fill_registration_form)
- la url della pagina di inserimento credenziali (Command = require_login)
*/

var socialINITs;

var socialConfig;

var initSocialAPIs = function (WKIlogged, configuration) {
    socialConfig = configuration;

    if (!socialConfig)
        socialConfig = {};

    if (!socialConfig.checkIdentitysUrl)
        socialConfig.checkIdentitysUrl = 'www.altalex.it/confermaIdentita';

    if (!socialConfig.registrationUrl)
        socialConfig.registrationUrl = 'www.altalex.it/area-personale/registrazione';

    if (!socialConfig.buttons)
        socialConfig.buttons = {};

    if (!socialConfig.socialSpinnerImageUrl)
        socialConfig.socialSpinnerImageUrl = '/Content/images/spinner.gif';

    if (!socialConfig.sessionCheckFunction)
        socialConfig.sessionCheckFunction = SessionRemoteCheck;

    if (!socialConfig.accessWithLabelHtml)
        socialConfig.accessWithLabelHtml = "<span>Accedi con:</span>";

    if (!socialConfig.disableUnbind)
        socialConfig.disableUnbind = false;

    socialINITs = $.map(socialAPIs, function (api) {
        return api.init();
    });
    $.when.apply($, socialINITs).done(function () {
        var socialLoggeds = $.map(socialAPIs, function (api) {
            return " " + api.name + ": " + api.logged;
        });
        console.log("SOCIAL APIS INITED! WKIlogged: " + WKIlogged + "," + socialLoggeds.toString());

        //Nel caso in cui non si � loggati in WKI ma si � loggati in almeno un social, tento il login automatico solo se non � stato appena fatto un logout manuale
        var anySocialLodded = $.inArray(true, $.map(socialAPIs, function (api) { return api.logged; })) > -1;

        if (!WKIlogged && anySocialLodded && manualLogout == false) {
            //TENTO IL LOGIN AUTOMATICO con la prima social API logged (utilizzo order per ordinare)
            var socialAPIsLoggedSorted = $.grep($.map(socialAPIs, function (api) { return api; }), function (api) { return api.logged; }).sort(function (a, b) { return a.order - b.order; });

            var social = socialAPIsLoggedSorted[0];

            social.getSocialData().done(function (socialdata) {
                //CHIAMATA JSONP
                $.getJSON(socialBackEndUrl + "SocialWS.asmx/SocialLogin?jsoncallback=?", { socialDataJson: encodeURIComponent(JSON.stringify(socialdata)), isAutomatic: true, wkiSessionCookieValue: getCookie('WKISESSION') })
                .done(function (data) {
                    if (data.Command == "logged") {
                        for (var x in data.Cookies) {
                            var c = data.Cookies[x];
                            setCookie(c.Name, c.Value, c.Expire);
                        }
                        socialConfig.sessionCheckFunction(); //L'utente � stato loggato: ricarico la pagina
                    }
                    else if (data.Command == "require_login") {
                        if (socialConfig.checkIdentitysUrl == window.location.pathname || socialConfig.registrationUrl == window.location.pathname)
                            return;
                        postToUrl(socialConfig.checkIdentitysUrl, socialdata);
                    }
                });
            });
        }
    });
};

//function richiamata dai pulsanti di accesso col social network
var socialLogin = function(containerSelector, errorsContainerSelector, socialName, errorElementSelector, registrationUrl, checkIdentitysUrl, sessionCheckFunction, loginOkUrl) {
    var social = socialAPIs[socialName];

    //$(containerSelector).html("<div style='margin-bottom: 15px;'>Caricamento delle funzionalit&agrave; social...</div><img src='" + socialConfig.socialSpinnerImageUrl + "' />");

    //$.when.apply($, socialINITs).done(function() {

    $(containerSelector + " .socialButtons").hide();
    $(containerSelector + " .socialSpinner").show();

    $.when(social.deferredInit).done(function() { //Per sicurezza chiamo l'api dopo che l'sdk � gi� stato caricato
        social.login()
            .done(function() {
                $(containerSelector + " .socialButtons").hide();
                $(containerSelector + " .socialSpinner").show();
            
                social.getSocialData().done(function(socialdata) {
                    //CHIAMATA JSONP
                    $.getJSON(socialBackEndUrl + "SocialWS.asmx/SocialLogin?jsoncallback=?", { socialDataJson: encodeURIComponent(JSON.stringify(socialdata)), isAutomatic: false, wkiSessionCookieValue: getCookie('WKISESSION') })
                    .done(function(data) {
                        if (data.Command == "logged") {
                            for (var x in data.Cookies) {
                                var c = data.Cookies[x];
                                setCookie(c.Name, c.Value, c.Expire);
                            }

                            sessionCheckFunction ? sessionCheckFunction() : socialConfig.sessionCheckFunction(); //L'utente � stato loggato: ricarico la pagina
                        }
                        else if (data.Command == "fill_registration_form") {
                            postToUrl(registrationUrl || socialConfig.registrationUrl, socialdata);
                        }
                        else if (data.Command == "require_login") {
                            socialdata.loginOkUrl = loginOkUrl;
                            postToUrl(checkIdentitysUrl || socialConfig.checkIdentitysUrl, socialdata);
                        }

                        $(containerSelector + " .socialSpinner").hide();
                        $(containerSelector + " .socialButtons").show();
                    })
                    .fail(function() {
                        $(containerSelector + " .socialSpinner").hide();
                        $(containerSelector + " .socialButtons").show();
                    });
                })
                .fail(function() {
                    $(containerSelector + " .socialSpinner").hide();
                    $(containerSelector + " .socialButtons").show();
                });
            });
    }).always(function() {
        $(containerSelector + " .socialSpinner").hide();
        $(containerSelector + " .socialButtons").show();
    });
};

//function che genera i pulsanti di accesso social all'interno di un elemento
var generateSocialAccessButtons = function(containerSelector, errorsContainerSelector, accessWithLabelHtml, registrationUrl, checkIdentitysUrl, sessionCheckFunction, loginOkUrl) {

    //$(containerSelector).html("<div style='margin-bottom: 15px;'>Caricamento delle funzionalit&agrave; social...</div><img src='" + socialConfig.socialSpinnerImageUrl + "' />");

    //$.when.apply($, socialINITs).done(function() {

    var socialAPIsInitedSorted = $.grep($.map(socialAPIs, function(api) { return api; }), function(api) { return true; /*api.inited;*/ }).sort(function(a, b) { return a.order - b.order; });

    if (socialAPIsInitedSorted.length > 0) {
        $(containerSelector).html('<div style="margin-bottom: 15px;">' + (accessWithLabelHtml ? accessWithLabelHtml : socialConfig.accessWithLabelHtml) + '</div>');
        var sb = $("<div class='socialButtons "+(socialConfig.buttonsWrapperClass || "")+"'></div>)");
        $(containerSelector).append(sb);

        //html += "<div class='socialButtons'>";
        for (var i = 0; i < socialAPIsInitedSorted.length; i++) {
            var social = socialAPIsInitedSorted[i];

            var fromHtml = false;
            if (socialConfig.buttonsHtml) {
                var buttonHtml = socialConfig.buttonsHtml[social.name];
                if (buttonHtml) {
                    fromHtml = true;
                    var button = $(buttonHtml);
                    sb.append(button);
                    var clickable = $(".socialclick", button);
                    if (clickable.length == 0)
                        clickable = button;
                    clickable.attr("data-socialname", social.name);
                    clickable.bind("click", function(event) {
                        var socialname = $(event.currentTarget).attr("data-socialname");
                        socialLogin(containerSelector, errorsContainerSelector, socialname, errorsContainerSelector, registrationUrl, checkIdentitysUrl, sessionCheckFunction, loginOkUrl);
                    });
                }
            }

            if (!fromHtml) {

                var imageUrl = socialConfig.buttons[social.name];
                if (!imageUrl)
                    imageUrl = social.accessButtonDefaultImageUrl;

                var img = $('<img src="' + imageUrl + '" data-socialname="' + social.name + '" style="cursor: pointer; padding-left: 10px; padding-right: 10px;"/>');
                sb.append(img);
                img.bind("click", function(event) {
                    var socialname = $(event.currentTarget).attr("data-socialname");
                    socialLogin(containerSelector, errorsContainerSelector, socialname, errorsContainerSelector, registrationUrl, checkIdentitysUrl, sessionCheckFunction, loginOkUrl);
                });
            }
            //html += '<img src="' + imageUrl + '" style="cursor: pointer; padding-left: 10px; padding-right: 10px;" onclick="socialLogin(\'' + containerSelector + '\', \'' + errorsContainerSelector + '\', \'' + social.name + '\', \'' + errorsContainerSelector + '\');" />';
        }
        //html += "</div>";
        $(containerSelector).append("<img style='display: none;' class='socialSpinner' src='" + socialConfig.socialSpinnerImageUrl + "' />");
    }
    //});
};

var generateSocialBinderButtons = function (containerSelector, errorsContainerSelector, isAlx) {

    if (typeof isAlx == 'undefined') {
        isAlx = false;
    }

    $(containerSelector).html("<div style='margin-bottom: 15px;'>Caricamento delle funzionalit&agrave; social...</div><img src='" + socialConfig.socialSpinnerImageUrl + "' />");

    //$.when.apply($, socialINITs).done(function () {

    var html = "";

    var socialAPIsInitedSorted = $.grep($.map(socialAPIs, function(api) { return api; }), function(api) { return true; /*api.inited;*/ }).sort(function(a, b) { return a.order - b.order; });

    if (socialAPIsInitedSorted.length > 0) {

        var socialfarms = $.map(socialAPIsInitedSorted, function(api) { return api.farm; });

        //CHIAMATA JSONP
        $.getJSON(socialBackEndUrl + "SocialWS.asmx/GetSocialBinded?jsoncallback=?", { socialFarmsJson: encodeURIComponent(JSON.stringify(socialfarms)), ui: getCookie("WKITICKET") })
            .done(function(data) {
                for (var x in socialAPIsInitedSorted) {
                    var api = socialAPIsInitedSorted[x];

                    var bind = $.inArray(api.farm, data) > -1;
                    if (isAlx) {
                        html += getSocialBindLinkHtmlAlx(api, !bind);
                    }
                    else {
                        html += getSocialBindLinkHtml(api, !bind);
                    }
                    
                }

                //html += "<img style='display: none;' class='socialSpinner' src='" + socialConfig.socialSpinnerImageUrl + "' />";
                $(containerSelector).html(html);
            });
    }
    //});
};

var getSocialBindLinkHtml = function (api, bind) {
    if (!bind && socialConfig.disableUnbind)
        return "";

    return '<div class="socialBinder"><a href="#" onclick="socialBind(this, \'' + api.name + '\', ' + (bind ? 'true' : 'false') + ');">' + (bind ? 'Collega' : 'Scollega') + ' il tuo account ' + api.name + '</a></div>'
};

var getSocialBindLinkHtmlAlx = function (api, bind) {
    if (!bind && socialConfig.disableUnbind)
        return "";

    return '<div class="socialBinder socialBinder--' + api.name.toLowerCase() + '"><a href="#" onclick="socialBind(this, \'' + api.name + '\', ' + (bind ? 'true' : 'false') + ');">' + (bind ? 'Collega' : 'Scollega') + ' il tuo account ' + api.name + '</a></div>'
};

var socialBind = function (element, socialName, bind) {
    var social = socialAPIs[socialName];
    $("<img style='height: 20px;' class='socialSpinner' src='" + socialConfig.socialSpinnerImageUrl + "' />").insertAfter($(element).parent());
    $.when(social.deferredInit).done(function () { //Per sicurezza chiamo l'api dopo che l'sdk � gi� stato caricato
        social.login()
            .done(function () {
                $("<img style='height: 20px;' class='socialSpinner' src='" + socialConfig.socialSpinnerImageUrl + "' />").insertAfter($(element).parent());
                $(element).parent().hide();//.append("<img style='height: 20px;' class='socialSpinner' src='" + socialConfig.socialSpinnerImageUrl + "' />");

                social.getSocialData().done(function (socialdata) {
                    //CHIAMATA JSONP
                    $.getJSON(socialBackEndUrl + "SocialWS.asmx/SocialBind?jsoncallback=?", { socialFarm: social.farm, socialId: socialdata.Id, bind: bind, ui: getCookie("WKITICKET") })
                    .done(function (data) {
                        if (data == "BOUND") {
                            $(element).parent().next().detach();
                            $(element).parent().replaceWith(getSocialBindLinkHtml(social, false));
                        }
                        else if (data == "UNBOUND") {
                            $(element).parent().next().detach();
                            $(element).parent().replaceWith(getSocialBindLinkHtml(social, true));
                        }
                        else {
                            $(element).parent().next().detach();
                            $(element).parent().show();
                        }
                    })
                    .fail(function () {
                        $(element).parent().next().detach();
                        $(element).parent().show();
                    });
                })
                .fail(function () {
                    $(element).parent().next().detach();
                    $(element).parent().show();
                });
            });
        }).always(function() {
            $(element).parent().next().detach();
            $(element).parent().show();
        });
};

//function che reindirizza ad una url inviando un JSON
var postToUrl = function (url, data) {
    var json = JSON.stringify(data);
    var input = $('<input type="hidden" name="socialdata" value="" />');
    input.val(json);
    var form = $('<form action="' + url + '" method="post"></form>');
    form.append(input);
    $("body").append(form);
    form.submit();
};

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1);
        if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
    }
    return "";
}

function setCookie(cname, cvalue, expirationdate) {
    var d = new Date(parseInt(expirationdate.substr(6)));
    //d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}